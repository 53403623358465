import oucy from "@/util/oucyUtil";
import { stock ,schemeOrder} from "@/service/index"
import {localSet, localGet, localDel} from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {

            pageWidth: oucy.pageWidth,

            rules: {

                schemeName: [
                    { required: true, message: '请输入方案名称', trigger: 'change' }
                ],
                schemeInformation: [
                    { required: true, message: '请输入方案内容', trigger: 'blur' }
                ]
            },
            schemeId: '',
            allScheme: [],
            ruleForm: {
                schemeName: '', //方案名称
                schemeDefault: false, //是否默认方案
                schemeInformation: '', //方案内容

                schemeIsPublic:'', //是否公开
                schemeCover: '', //方案封面
                schemeType: ''//方案分类编码
            },
            num: 1,
            dialogVisible: !true,
            dialogVisible222: !true,
            text: '',
            textarea: '',
            list: [],
            obj: {},
            TheSelectedItems: 0,
            // checkAll: [0,0],
            // isIndeterminate: true
            // 调整 类型
            // 将总优惠金额按折扣分配至每件单品
            AdjustPriseWithTotalPrise: {
                adjustPriseWithType: 'adjustPriseWithTotalPrise',
                distribution: false,
                totalPrise: null,
                discount: null,
                reducePrice: null,
            },
        }
    },
    computed: {
        isIndeterminate() {
            let select_true_num = 0,
                select_false_num = 0
            for (let v of this.list) {
                for (let vv of v.stockItems) {
                    if (vv.select) {
                        select_true_num++
                    } else {
                        select_false_num++
                    }
                }
            }
            this.TheSelectedItems = select_true_num
            return select_true_num > 0 && select_false_num > 0
        },
        checkAll: {
            get: function() {
                let select_true_num = 0,
                    select_false_num = 0
                for (let v of this.list) {
                    for (let vv of v.stockItems) {
                        if (vv.select) {
                            select_true_num++
                        } else {
                            select_false_num++
                        }
                    }
                }
                return select_true_num > 0 && select_false_num == 0
            },
            set: function(newValue) {}
        },
        beforePrise: {
            get: function() {
                let prise = 0
                for (let v of this.list) {
                    for (let vv of v.stockItems) {
                        if (vv.itemInvalidStatus == 0 && vv.select) {
                            prise +=  vv.itemPrise * vv.itemCount
                        }
                    }
                }
                return prise
            },
            set: function(newValue) {}
        },
        beforePrise2: {
            get: function() {
                let prise = 0
                let isVIP = false
                for (let v of this.list) {
                    for (let vv of v.stockItems) {
                        if (vv.itemInvalidStatus == 0 && vv.select) {
                            prise += (vv.itemNumberPrise || vv.itemPrise) * vv.itemCount
                            if (vv.itemNumberPrise!=0) {
                                isVIP=true
                            }
                        }
                    }
                }
                if (isVIP) {
                    return {itemNumberPrise:prise,itemCount:1}
                }else{
                    return {}
                }
                
            },
            set: function(newValue) {}
        },
    },
    mounted() {
        this.getAllScheme()
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.ruleForm).then(res=>{
               this[fn]() 
            },err=>{})
        },
        handleChange(v, i) {
            stock.updateItemCount({ schemeItemId: v.itemId, num: v.itemCount }).then(res => {
                oucy.app.eventBus.$emit(this.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM, null);
            })
        },
        handleClose(done) {
                    done();
                    return
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        // 添加
        createSchema() {
            stock.createSchema(this.ruleForm).then(res => {
                this.closeDialogVisible()
                this.getAllScheme()
            })
        },
        // 查询
        getAllScheme() {
            stock.getAllScheme({}).then(res => {
                this.allScheme = res || []
                if (this.allScheme && this.allScheme[0]) {
                    this.schemeId = this.allScheme[0].schemeId
                    this.getBySchemeId()
                }
            })
        },
        // 修改
        modifySchemaBasicInfo() {
            stock.modifySchemaBasicInfo(this.ruleForm).then(res => {
                this.closeDialogVisible()
                this.getBySchemeId()
            })
        },
        // 删除
        delSchema() {
            stock.delSchema({ schemeId: this.schemeId }).then(res => {
                this.schemeId = null
                this.closeDialogVisible()
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM, null);
                // this.getBySchemeId()
            })
        },
        // 根据scheme编码查询进货车方案详细信息
        getBySchemeId() {
            stock.getBySchemeId({ schemeId: this.schemeId }).then(res => {
                if(res.stockEnterprises){
                    for (let v of res.stockEnterprises) {
                        for (let vv of v.stockItems) {
                            vv.select = false
                        }
                    }
                }
                this.obj = res
                this.list = res.stockEnterprises||[]
            })

        },
        // 移除商品
        removeItem(v) {
            stock.removeItem({ schemeItemId: v.itemId }).then(res => {
                this.getBySchemeId()
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.UPDATA_STOCK_CAR_NUM, null);
            })
        },
        // 清除失效商品
        clearInvalidItems() {
            stock.clearInvalidItems({ schemeId: this.schemeId }).then(res => {
                this.getBySchemeId()
            })
        },
        // 点开选
        handleCheckAllChange() {
            if (this.checkAll == true) {
                for (let v of this.list) {
                    for (let vv of v.stockItems) {
                        vv.select = false
                    }
                }
            } else {
                for (let v of this.list) {
                    for (let vv of v.stockItems) {
                        vv.select = true

                    }
                }
            }
        },
        // 打开 新建 编辑
        openDialogVisible(v) {
            if (v) {
                this.ruleForm = v
            } else {
                this.ruleForm = {
                    schemeName: '',
                    schemeDefault: false,
                    schemeInformation: '',
                }
            }
            this.dialogVisible = true
        },
        closeDialogVisible() {
            this.dialogVisible = false
        },
        createOrder() {
            this.queryTmpOrderScheme()
            // oucy.go('/confirmOrder')
        },
        editscheme() {
            for (let v of this.allScheme) {
                if (v.schemeId == this.schemeId) {
                    this.openDialogVisible(v)
                    break
                }
            }
        },
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.schemeId) {
                        this.modifySchemaBasicInfo()
                    } else {
                        this.createSchema()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        submit() {
            // 总定义总金额
            if (this.AdjustPriseWithTotalPrise.adjustPriseWithType == 'adjustPriseWithTotalPrise') {
                if (!this.AdjustPriseWithTotalPrise.totalPrise) {
                    this.alert('请输入总金额')
                    return
                }

            } else
            if (this.AdjustPriseWithTotalPrise.adjustPriseWithType == 'adjustPriseWithDiscount') {
                if (!this.AdjustPriseWithTotalPrise.discount && this.AdjustPriseWithTotalPrise.discount != 0) {
                    this.alert('请输入打折')
                    return
                }
                if (this.AdjustPriseWithTotalPrise.discount < 0 || this.AdjustPriseWithTotalPrise.discount > 10) {
                    this.alert('打折需在 0~10 之间')
                    return
                }
            } else
            if (this.AdjustPriseWithTotalPrise.adjustPriseWithType == 'adjustPriseWithDiscount') {
                if (!this.AdjustPriseWithTotalPrise.reducePrice && this.AdjustPriseWithTotalPrise.reducePrice != 0) {
                    this.alert('请输入减去金额')
                    return
                }
                if (this.AdjustPriseWithTotalPrise.reducePrice >this.beforePrise) {
                    this.alert('减去金额不能大于>调整前方案总金额')
                    return
                }
            }
            this.AdjustPriseWithTotalPrise.schemeId=this.schemeId
            stock[this.AdjustPriseWithTotalPrise.adjustPriseWithType](this.AdjustPriseWithTotalPrise).then(res=>{
                console.log(res)
            })
            this.dialogVisible222 = false
        },
        alert(text, title) {
            this.$alert(text, title || '输入内容不正确', {
                confirmButtonText: '确定',
            });
        },
        queryTmpOrderScheme(){
            let schemeItemIds=[]
            let has=false
            for (let v of this.list) {
                for (let vv of v.stockItems) {
                    if (vv.select && vv.itemId) {
                       schemeItemIds.push(vv.itemId)
                       if(vv.itemPrise==0 || vv.itemPrise=='0'|| vv.itemPrise=='0.00'){
                        has=true
                        break
                       }
                    } else {
                       
                    }
                }
                if(has)break
            }
            if(has){
                this.alert('您所选择的商品中包含"面议"商品，无法生成订单','提示')
                return
            }
            let data={
                schemeId:this.schemeId,
                schemeItemIds:JSON.stringify(schemeItemIds)
            }
            schemeOrder.queryTmpOrderScheme(data).then(res=>{
                res.schemeId=this.schemeId
                res.beforePrise2=this.beforePrise2
                localSet('tmpOrder',res)
                this.$oucy.go(`/acc/confirmOrder?schemeId=${this.schemeId}`)
            })
        },
        gotoNewWin(v){
            window.open(location.origin+'/Shop?enterpriseId='+v.enterpriseId)
        }
    }
}