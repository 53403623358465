<template>
    <div>
        <!-- <stock></stock> -->
        <div class="container">
            <div class="pageCenter p-l-10 p-r-10">
                <el-container>
                    <div class="p-b-30 p-t-20" style="width: 100%;">
                        <!-- <h2 style="padding: 30px 0">乐搜家进货车管理中心</h2> -->
                        <!-- 有货 -->
                        <div class="content">
                            <div class="contentTop">
                                <div class="contentTopLeft">
                                    <div class="contentTopLeftBts">
                                        <el-button-group>
                                            <el-button type="primary">进货车</el-button>
<!--                                            <el-button>分销车</el-button>-->
                                        </el-button-group>
                                    </div>
                                </div>
                                <div class="contentTopRight">
                                    <span class="m-r-20 f14">当前方案</span>
                                    <!-- <template> -->
                                    <el-select v-model="schemeId" placeholder="请选择" @change="getBySchemeId">
                                        <el-option v-for="item in allScheme" :key="item.schemeId" :label="item.schemeName" :value="item.schemeId">
                                        </el-option>
                                    </el-select>
                                    <!-- </template> -->
                                    <el-button-group class="m-l-10">
                                        <el-button @click="editscheme" v-if="schemeId">编辑方案</el-button>
                                        <el-button @click="delSchema" v-if="schemeId">删除方案</el-button>
                                        <el-button @click="openDialogVisible(null)">添加新方案</el-button>
                                    </el-button-group>
                                </div>
                            </div>
                            <div class="contentCenter">
                                <div v-if="list && list.length">
                                    <div class="table">
                                        <div class="tableHeader m-t-25">
                                            <div class="cell-1">
                                                <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
                                            </div>
                                            <div class="cell-2">商品信息</div>
                                            <div class="cell-3">单价</div>
                                            <div class="cell-4">数量</div>
                                            <div class="cell-5">价格</div>
                                            <div class="cell-6">操作</div>
                                        </div>
                                        <div class="tableBody">
                                            <div v-for="(v,i) of list" :key="i">
                                                <div class="itemsHeader f14 m-t-25"><li class="el-icon-s-shop" style="font-size: 20px;color: #2894FF;margin-left: 13px"></li>商家：<span class="pointer" @click="gotoNewWin(v)">{{v.enterpriseName}}</span></div>
                                                <div class="items m-t-10">
                                                    <div class="item p-t-10 p-b-10" v-for="(vv,ii) of v.stockItems" :key="ii" :class="{'lose':vv.itemInvalidStatus!=0}" v-if="v.stockItems">
                                                        <div class="cell-1">
                                                            <!-- 失效代码：0正常 1商家下架 2商家删除 3库存不足 -->
                                                            <el-checkbox v-model="vv.select" v-if="vv.itemInvalidStatus==0"></el-checkbox>
                                                            <span class="checkboxLose f12" v-if="vv.itemInvalidStatus==1">商家<br>下架</span>
                                                            <span class="checkboxLose f12" v-if="vv.itemInvalidStatus==2">商家<br>删除</span>
                                                            <span class="checkboxLose f12" v-if="vv.itemInvalidStatus==3">库存<br>不足</span>
                                                        </div>
                                                        <div class="cell-2">
                                                            <div class="merchandise">
                                                                <div class="merchandiseImg"><img :src="$oucy.ossUrl+vv.itemAvata"></div>
                                                                <div class="merchandiseText p-l-10">
                                                                    <div class="name c-1 14 line-2">{{vv.itemName}}</div>
                                                                    <div class="specification c-2 m-t-15 f12 line-2">规格：{{vv.itemAttrDesc}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cell-3 unit">
                                                            <span v-if="vv.itemPrise==0">面议</span>
                                                            <span v-else>￥{{vv.itemPrise}}</span>
                                                        </div>
                                                        <div class="cell-4">
                                                            <span v-if="vv.itemInvalidStatus!=0" class="c-5 f12">{{vv.itemCount}}</span>
                                                            <el-input-number v-else size="mini" v-model="vv.itemCount" @change="handleChange(vv,ii)" :min="1" :max="10000" label="描述文字"></el-input-number>
                                                        </div>
                                                        <div class="cell-5 f14 c-40">
                                                            <span v-if="vv.itemPrise==0">面议</span>
                                                            <span v-else>￥{{(vv.itemCount*vv.itemPrise).toFixed(2)}}</span>
                                                              <!-- <VIPPrice :data="vv"></VIPPrice> -->
                                                            <div class="price" v-if=" vv.itemNumberPrise && (vv.itemNumberPrise*1) != NaN && (vv.itemNumberPrise*1)>0"><span class="text">会员价￥{{vv.itemNumberPrise*vv.itemCount}}</span></div>

                                                        </div>
                                                        <div class="cell-6">
                                                            <div>
                                                                <el-button size="mini" v-if="vv.itemInvalidStatus!=0">收藏</el-button>
                                                            </div>
                                                            <div class="m-t-5">
                                                                <el-button size="mini" @click="removeItem(vv)">删除</el-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 无货 -->
                                <div v-else>
                                    <img src="@/assets/images/stockCar/stockCarNot.png" class="stockCarNotImg">
                                    <div class="c-2 m-t-50 f14">您的进货车什么都没有呢，赶紧去添加吧~！</div>
                                    <el-button type="primary m-t-50 m-b-20" @click="$oucy.go('/')">去添加商品</el-button>
                                </div>
                            </div>
                            <div class="contentBottom">
                                <div class="contentBottomBody" >
                                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                                    <div class="del" v-if="0">
                                        <img src="@/assets/images/stockCar/del_icon.png" class="ont_hover">
                                        <img src="@/assets/images/stockCar/del_icon_hover.png" class="hover">
                                        删除
                                    </div>
                                    <div class="failure" @click="clearInvalidItems">
                                        <img src="@/assets/images/stockCar/Clear_failure_baby_icon.png" class="ont_hover">
                                        <img src="@/assets/images/stockCar/Clear_failure_baby_icon_hover.png" class="hover">
                                        清除失效宝贝
                                    </div>
                                    <div class="favorite" v-if="0">
                                        <img src="@/assets/images/stockCar/favorite_icon.png" class="ont_hover">
                                        <img src="@/assets/images/stockCar/favorite_icon_hover.png" class="hover">
                                        移至收藏夹
                                    </div>
                                    <div>
                                        <el-dropdown>
                                          <span class="el-dropdown-link">
                                            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                          </span>
                                          <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item><div @click="$oucy.go('/acc/offer?schemeId='+schemeId)">生成物料清单</div></el-dropdown-item>
                                          </el-dropdown-menu>
                                        </el-dropdown>
                                        <!-- <el-select v-model="schemeId" placeholder="请选择" @change="getBySchemeId">
                                            <el-option v-for="item in allScheme" :key="item.schemeId" :label="item.schemeName" :value="item.schemeId">
                                            </el-option>
                                        </el-select> -->
                                    </div>
                                    <div class="c-2">
                                        已选商品 <span>{{TheSelectedItems}}</span>件
                                    </div>
                                    <div class="c-2" v-if="0">
                                        调前方案金额: <span>￥{{beforePrise.toFixed(2)}}</span>
                                    </div>
                                    <div class="scheme">
                                        当前方案金额: 
                                        <div class="schemeVip">
                                         <span class="c-40 f16">￥{{beforePrise.toFixed(2)}}</span>
                                         <!-- 当前方案金额: <span class="price f24">￥{{obj.schemeAdjustPrise}}</span> -->
                                         <div class="price f16" v-if="beforePrise2.itemNumberPrise">会员价￥{{beforePrise2.itemNumberPrise}}</div>
                                          <!-- <VIPPrice :data="beforePrise2"></VIPPrice> -->
                                        </div>
                                    </div>
                                    <!-- not 去除色 -->
                                    <div class="setPriceCreateOrder" :class="{'not':TheSelectedItems==0}">
                                        <span v-if="0" class="setPrice" @click="TheSelectedItems!=0?dialogVisible222=true:''">调整总金额</span>
                                        <span class="createOrder" @click="TheSelectedItems!=0?createOrder():''">生成订单</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-container>

            </div>
        </div>
        <!-- 添加新方案 | 修改方案 -->
        <el-dialog title="添加新方案" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="方案名称" prop="schemeName">
                    <el-input v-model="ruleForm.schemeName" maxlength="20" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="方案内容" prop="schemeInformation">
                    <el-input type="textarea" v-model="ruleForm.schemeInformation" maxlength="120" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="默认方案" class="text-left">
                    <el-checkbox v-model="ruleForm.schemeDefault">设置为默认方案</el-checkbox>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="keywordFiltr('submitForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 调整方案总金额 -->
        <el-dialog title="调整方案总金额" :visible.sync="dialogVisible222" width="600px" :before-close="handleClose">
            <!-- <span>这是一段信息</span> -->
            <div class="df df-jc-s-b">
                <div>调整前方案总金额</div>
                <div class="price f24">￥{{beforePrise.toFixed(2)}}</div>
            </div>
            <div class="df df-jc-s-b df-ai-c m-t-20">
                <div>
                    <el-radio v-model="AdjustPriseWithTotalPrise.adjustPriseWithType" label="adjustPriseWithTotalPrise">总定义总金额</el-radio>
                </div>
                <div class="df df-ai-c"><span class="input-text">￥</span>
                    <el-input style="width: 210px;" v-model="AdjustPriseWithTotalPrise.totalPrise" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="df df-jc-s-b df-ai-c m-t-20">
                <div>
                    <el-radio v-model="AdjustPriseWithTotalPrise.adjustPriseWithType" label="adjustPriseWithDiscount">总价打折</el-radio>
                </div>
                <div class="df df-ai-c"><span class="input-text">打</span>
                    <el-input style="width: 185px;" v-model="AdjustPriseWithTotalPrise.discount" placeholder="请输入内容"></el-input><span class="input-text">折</span>
                </div>
            </div>
            <div class="df df-jc-s-b df-ai-c m-t-20">
                <div>
                    <el-radio v-model="AdjustPriseWithTotalPrise.adjustPriseWithType" label="adjustPriseWithReduce">减去金额</el-radio>
                </div>
                <div class="df df-ai-c"><span class="input-text">减</span>
                    <el-input style="width: 210px;" v-model="AdjustPriseWithTotalPrise.reducePrice" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <el-divider></el-divider>
            <div class="text-left">
                <el-checkbox v-model="AdjustPriseWithTotalPrise.distribution">将总优惠金额按折扣分配至每件单品</el-checkbox>
            </div>
            <div class="text-right">
                调整后方案总金额:
                <span class="price f24" v-if="AdjustPriseWithTotalPrise.adjustPriseWithType=='adjustPriseWithTotalPrise'">￥{{AdjustPriseWithTotalPrise.totalPrise||0}}</span>
                <span class="price f24" v-if="AdjustPriseWithTotalPrise.adjustPriseWithType=='adjustPriseWithDiscount'">￥{{beforePrise - beforePrise*(AdjustPriseWithTotalPrise.discount/10)}}</span>
                <span class="price f24" v-if="AdjustPriseWithTotalPrise.adjustPriseWithType=='adjustPriseWithReduce'">￥{{beforePrise-AdjustPriseWithTotalPrise.reducePrice}}</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible222 = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Index from './Index.js'
export default Index
</script>
<style lang="less" scoped>
@import "./Index.less";
</style>